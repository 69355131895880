<template>
  <div style="height: 100%">
    <div v-if="!isSelectCustomer" class="box">
      <div class="title">
        <span>创建短信推送计划</span>
        <span class="right"><span style="color: red">*</span>为必填</span>
      </div>
      <div class="content">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="120px"
          class="demo-ruleForm"
          size="small"
        >
          <el-form-item label="发送主题" prop="planTitle">
            <el-input
              v-model="ruleForm.planTitle"
              size="small"
              style="width: 400px"
              maxlength="20"
              placeholder="字数限制在20个字符以内"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="消息模板类型" prop="templateCategoryId">
            <el-select
              style="width: 400px"
              v-model="ruleForm.templateCategoryId"
              placeholder="请选择"
              @change="cateChange"
            >
              <el-option
                v-for="(item, i) in cateList"
                :key="i"
                :label="item.categoryName"
                :value="item.categoryId"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="消息模板名称" prop="templateId">
            <el-select
              v-model="ruleForm.templateId"
              style="width: 400px"
              placeholder="请选择"
              @change="nameChange"
            >
              <el-option
                v-for="(item, i) in nameList"
                :key="i"
                :label="item.templateName"
                :value="item.templateId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="消息模板内容" prop="templateContent">
            <el-input
              v-model="ruleForm.templateContent"
              readonly
              style="width: 400px"
              type="textarea"
              :rows="8"
              maxlength="500"
              show-word-limit
              placeholder="请输入消息模板内容"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="ruleForm.templateCode == 'SMS_234050909'"
            label="节日名称"
            prop="holiday"
          >
            <el-input v-model="ruleForm.holiday" size="small" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item label="客户范围" prop="receiverRangeKey">
            <el-radio-group v-model="ruleForm.receiverRangeKey" @change="radioChange">
              <el-radio v-for="(n, i) in customerCate" :key="i" :label="n.dictionaryValue">{{
                n.dictionaryName
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.receiverRangeKey == 2" label="标签" prop="customers">
            <el-link type="primary" @click="selectTags">点击选择标签</el-link>
            <br />
            <el-tag
              v-for="tag in tags"
              :key="tag.name"
              size="small"
              closable
              :type="tag.type"
              @close="close(1, tag)"
            >
              {{ tag.name }}
            </el-tag>
          </el-form-item>
          <el-form-item v-if="ruleForm.receiverRangeKey == 3" label="渠道" prop="customers">
            <el-link type="primary" @click="selectchan">点击选择渠道</el-link>
            <br />
            <el-tag
              v-for="tag in chanTags"
              :key="tag.channelId"
              size="small"
              closable
              :type="tag.type"
              @close="close(2, tag)"
            >
              {{ tag.channelName }}
            </el-tag>
          </el-form-item>
          <el-form-item v-if="ruleForm.receiverRangeKey == 4" label="客户人数" prop="customers">
            <span>{{ customerList.length }}</span
            >人
            <el-link type="primary" @click="selectCust">点击选择客户</el-link>
          </el-form-item>

          <el-form-item label="发送方式" prop="sendTime">
            <el-radio-group v-model="ruleForm.isDelaySend" @change="sendChange">
              <el-radio :label="0">立即发送</el-radio>
              <el-radio :label="1">定时发送</el-radio>
            </el-radio-group>
            <el-date-picker
              v-if="ruleForm.isDelaySend == 1"
              v-model="ruleForm.delaySendTime"
              popper-class="date-style"
              style="margin-left: 10px"
              type="datetime"
              :picker-options="pickerOption"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="footer">
          <el-button size="small" @click="cancel">取消</el-button>
          <el-button :loading="btnLoading" size="small" type="primary" @click="ok">保存</el-button>
        </div>
      </div>
    </div>
    <SelectCustomer
      v-if="isSelectCustomer"
      :checked-data="ruleForm.customersPlan"
      @cancel="isSelectCustomer = false"
      @customerOk="customerOk"
    />

    <SelectChan
      :total="total"
      :chan-tags="chanTags"
      :dialog-visible="dialogVisible"
      @cancel="dialogVisible = false"
      @ok="chanOk"
    />
    <setTagMangeVue
      :visible="visible"
      :value="ruleForm.receiverRangeValue"
      :tags-data="tagsData"
      :tag-loading="tagLoading"
      @ok="tagOk"
      @cancel="tagCancel"
    />
    <el-dialog
      title="确认提示"
      :visible.sync="confirmVisible"
      width="400px"
      :before-close="handleClose"
      class="modal"
    >
      <div v-loading="confirmLoading">
        <div style="display: flex; align-items: center; font-weight: bold; font-size: 18px">
          <i
            style="color: #fe9400; font-size: 30px; margin-right: 10px"
            class="el-icon-question"
          ></i
          >创建短信推送计划确认
        </div>
        <div style="margin-left: 40px">
          预计发送客户人数：<span style="color: #d9001b">{{ num }}</span>
        </div>
        <div style="margin-left: 40px">是否确定创建短信推送？</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="confirmVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import SelectCustomer from '@/views/components/selectCustomer'
import SelectChan from '@/views/components/selectChan'
import setTagMangeVue from '@/views/components/selectTags'
import {
  getCustomerNum,
  chooseMessTemplateByTouch,
  addMessagePlan,
  queryChildsById,
  getChannelPage,
} from '@/api'
export default {
  components: {
    SelectCustomer,
    SelectChan,
    setTagMangeVue,
  },
  data() {
    return {
      confirmLoading: false,
      confirmVisible: false,
      ruleForm: {
        receiverRangeKey: '2',
        templateId: '',
        customers: '',
        isDelaySend: 0,
        delaySendTime: '',
        receiverRangeValue: [],
        sendTime: '',
        customersPlan: [],
        templateContent: '',
      },

      btnLoading: false,
      rules: {
        planTitle: [{ required: true, message: '请输入', trigger: 'change' }],
        holiday: [{ required: true, message: '请输入', trigger: 'change' }],
        receiverRangeKey: [{ required: true, message: '请选择', trigger: 'change' }],
        templateContent: [{ required: true, message: '请选择', trigger: 'change' }],
        templateId: [{ required: true, message: '请选择', trigger: 'change' }],
        templateCategoryId: [{ required: true, message: '请选择', trigger: 'change' }],
        customers: [{ required: true, message: '请选择', trigger: 'change' }],
        delaySendTime: [{ required: true, message: '请选择', trigger: 'change' }],
        sendTime: [{ required: true, message: '请选择时间', trigger: 'blur' }],
      },
      tags: [],
      tagsData: [],
      chanTags: [],
      showTime: false,
      isSelectCustomer: false,
      dialogVisible: false,
      selectList: [],
      visible: false,
      tagLoading: false,
      customerList: [],
      cateList: [],
      nameList: [],
      customerCate: [],
      total: 0,
      pickerOption: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        },
        selectableRange: '00:00:00 - 23:59:59',
      },
      num: 0,
    }
  },
  watch: {
    'ruleForm.delaySendTime': {
      // 需要对绑定的时间进行监听
      handler(newValue, oldValue) {
        if (newValue) {
          this.ruleForm.sendTime = '1'
          const date = new Date() // 当前时间
          const min = date.getMinutes() // 当前时间的分
          date.setMinutes(min)
          const nowDate = moment(date).format('HH:mm:ss') // 当前时分秒
          let st = ''
          if (moment(date).format('yyyy-MM-DD') === moment(newValue).format('yyyy-MM-DD')) {
            const hh1 = moment(newValue).format('HH:mm:ss')
            if (hh1 <= nowDate) {
              this.ruleForm.delaySendTime = moment(date).format('yyyy-MM-DD HH:mm:ss')
            }
            st = nowDate
          } else {
            st = '00:00:00'
          }
          this.pickerOption.selectableRange = st + '-23:59:59'
        } else {
          this.ruleForm.sendTime = ''
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.messageTemplateCategory();
    this.queryChildsById()
    this.chooseMessTemplateByTouch()
    getChannelPage({ data: {}, pageNumber: 1, pageSize: 10 }).then((ress) => {
      this.total = ress.data.total
    })
  },
  methods: {
    sendChange() {
      this.ruleForm.delaySendTime = ''
    },
    radioChange() {
      this.ruleForm.receiverRangeValue = []
      this.tags = []
      this.customerList = []
      this.chanTags = []
      this.ruleForm.customers = ''
    },
    // getTags() {
    //   getTagTree({ data: { tagValueIds: [] } }).then((res) => {
    //     this.tagsData = [...res.data]
    //     this.ruleForm.receiverRangeValue.forEach((v) => {
    //       this.filterData(res.data, v)
    //     })
    //   })
    // },
    filterData(data, v) {
      data.forEach((e) => {
        if (e.id === v) {
          this.tags.push(e)
        } else {
          if (e.children) {
            this.filterData(e.children, v)
          }
        }
      })
    },
    queryChildsById() {
      this.customerCate = []
      queryChildsById({
        data: { key: 'message_scope' },
      }).then((res) => {
        if (res.data.childs) {
          res.data.childs.forEach((e) => {
            if (e.dictionaryKey !== 'all_customer') {
              this.customerCate.push(e)
            }
          })
        }
      })
    },
    // messageTemplateCategory() {
    //   messageTemplateCategory({ data: {} }).then((res) => {
    //     this.cateList = res.data;
    //   });
    // },
    selectCust() {
      this.isSelectCustomer = true
    },
    selectchan() {
      this.dialogVisible = true
    },
    selectTags() {
      this.visible = true
    },
    chooseMessTemplateByTouch(e) {
      chooseMessTemplateByTouch({
        data: '',
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          this.nameList = res.data
        }
      })
    },
    nameChange(e) {
      this.nameList.forEach((v) => {
        if (v.templateId === e) {
          this.ruleForm.templateContent = v.templateContent
          this.ruleForm.templateCode = v.templateCode
        }
      })
    },
    close(flag, row) {
      console.log(this.tags)
      flag = Number(flag)
      if (flag === 1) {
        for (let i = 0; i < this.tags.length; i++) {
          if (this.tags[i].id === row.id) {
            this.tags.splice(i, 1)
          }
        }
      }
      if (flag === 2) {
        for (let i = 0; i < this.chanTags.length; i++) {
          if (this.chanTags[i].id === row.id) {
            this.chanTags.splice(i, 1)
          }
        }
      }
    },
    tagOk(e) {
      this.ruleForm.customers = '1'
      this.tags = [...e]
      this.visible = false
      this.ruleForm.receiverRangeValue = []
      this.tags.forEach((e) => {
        this.ruleForm.receiverRangeValue.push(e.id)
      })
    },
    tagCancel() {
      this.visible = false
    },
    chanOk(e) {
      this.dialogVisible = false
      this.ruleForm.customers = '1'
      this.chanTags = e
      this.ruleForm.receiverRangeValue = []
      this.chanTags.forEach((e) => {
        this.ruleForm.receiverRangeValue.push(e.id)
      })
    },
    ok() {
      const isDelaySend = this.ruleForm.isDelaySend
      if (isDelaySend === 0) {
        this.ruleForm.sendTime = '1'
      }
      if (
        isDelaySend === 1 &&
        Number(moment().format('X')) - Number(moment(this.ruleForm.delaySendTime).format('X')) > 0
      ) {
        this.$message({
          message: '定时发送时间不能小于当前时间',
          type: 'warning',
        })
        return
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmVisible = true
          if (this.ruleForm.receiverRangeKey !== 4) {
            this.confirmLoading = true
            getCustomerNum({
              data: {
                receiverRangeKey: this.ruleForm.receiverRangeKey,
                receiverRangeValue: this.ruleForm.receiverRangeValue,
              },
            })
              .then((res) => {
                this.num = res.data
                this.confirmLoading = false
              })
              .catch(() => {
                this.confirmLoading = false
              })
          } else {
            this.num = this.customerList.length
          }
        } else {
          return false
        }
      })
    },
    handleOk() {
      const obj = { ...this.ruleForm }
      if (obj.delaySendTime) {
        obj.delaySendTime = moment(obj.delaySendTime).format('YYYY-MM-DD HH:SS:MM')
      }
      obj.defaultTemplateParameter = {
        holiday: obj.holiday,
      }
      delete obj.customers
      delete obj.sendTime
      delete obj.holiday
      const data = {
        ...obj,
        planType: 1,
        messageType: 1,
        receiverType: 1,
      }
      this.btnLoading = true
      addMessagePlan({
        data,
      })
        .then((res) => {
          this.btnLoading = false
          if (res.success) {
            this.$message({
              message: '短信发送任务创建成功',
              type: 'success',
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 500)
          }
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    customerOk(val) {
      this.isSelectCustomer = false
      this.ruleForm.customers = val && val.length > 0 ? '1' : ''
      if (val) {
        this.ruleForm.receiverRangeValue = []
        this.ruleForm.customersPlan = []
        this.customerList = []
        val.forEach((e) => {
          this.ruleForm.receiverRangeValue.push(e.customerId)
          this.ruleForm.customersPlan.push({
            customerId: e.customerId,
            content: JSON.stringify({
              channelName: e.channelName,
              customerName: e.customerName,
              cityName: e.cityName,
            }),
          })
          this.customerList.push(e)
        })
      }
    },
    cancel() {
      this.$router.go(-1)
    },
    handleClose() {
      this.num = 0
      this.confirmVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  border: 1px solid #eee;
  background-color: #fff;
}
.content {
  padding: 20px;
  border: none;
  .footer {
    text-align: center;
  }
}
.title {
  padding: 10px 50px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  .right {
    font-size: 14px;
    color: #999;
  }
}
.el-tag {
  margin-right: 10px;
}
::v-deep .modal {
  .el-dialog__body {
    padding: 10px 30px;
  }
}
</style>
